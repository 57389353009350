<template>
  <div class="flex w-full h-full">
    <div class="hidden w-full max-w-6xl sm:block bg-ribon-100" style="background-image: radial-gradient(100% 100% at 100% 0%, rgba(0, 45, 114, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #E6EEFC" />
    <div class="flex items-center w-full p-12 bg-white shadow-xl max-w-screen-3xl">
      <div class="w-full max-w-lg mx-auto">
        <slot />
      </div>
    </div>
  </div>
</template>
<style lang="postcss" scoped>
html {
  @apply min-h-full;
}

html,
body,
#__nuxt,
#__layout {
  @apply h-full;
}

body {
  @apply text-gray-500 font-sans text-sm leading-none;
}

.page-enter-active,
.page-leave-active {
  @apply transition-opacity duration-150;
}
.page-enter,
.page-leave-to {
  opacity: 0;
}

.layout-enter-active,
.layout-leave-active {
  @apply transition-opacity duration-150;
}
.layout-enter,
.layout-leave-to {
  opacity: 0;
}
</style>
